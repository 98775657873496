import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c(VForm,{staticClass:"custom-form white shadow1 px-2 py-2 rounded"},[_c(VRow,{staticStyle:{"align-items":"center"},attrs:{"dense":"","justify":"center"}},[_c(VCol,{attrs:{"cols":"1"}},[_vm._v("Invoice No")]),_c(VCol,{attrs:{"cols":"2"}},[_c(VCombobox,{attrs:{"dense":"","outlined":"","hide-details":"","items":_vm.$store.getters['sale/invoices'],"loading":_vm.$store.getters['sale/loadingsale'],"item-text":"invoice","item-value":"id"},on:{"focus":function($event){return _vm.$store.dispatch('sale/getInvoices')}},model:{value:(_vm.sale),callback:function ($$v) {_vm.sale=$$v},expression:"sale"}})],1)],1)],1),_c(VDivider),_c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{attrs:{"cols":"12"}},[(_vm.showInvoice)?_c('sale-invoice',{attrs:{"saleId":_vm.saleId}}):_c('div',{staticClass:"white shadow1 rounded"},[_c('div',{staticClass:"no_result"},[_c('div',{staticClass:"img"})])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }